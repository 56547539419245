<template>
  <div>
    <Card body-classes="">
      <template v-slot:header>
        <div class="d-flex align-items-center justify-content-between">
          <div class="mb-0 w-10">
            <span>Курсы</span>
          </div>

          <div class="mb-0 w-65">
            <Button
              type="success"
              @click="newCourse()"
            >
              Добавить новый
            </Button>
          </div>

          <Input
            v-model="dataTableSearch"
            size="sm"
            placeholder="Поиск"
            class="mb-0 w-25"
          />
        </div>
      </template>

      <div>
        <DataTable
          class="table-bordered"
          show-filter-buttons
          :data="dataTable"
          :headers="dataTableHeaders"
          :search-keyword="dataTableSearch"
          :per-page="dataTablePerPage"
          :current-page="dataTablePage"
        >
          <template v-slot:default="{ row, rowKey, rowKeyViewport }">
            <Cell
              v-for="(cellData, key) in row"
              :key="key"
              :class="[{'bg-gray-200': false}]"
            >
              <span v-if="key.toString().toLowerCase() === '_id'">{{ rowKey + 1 }}</span>
              <span v-else-if="key.toString().toLowerCase() === 'edit'">
                <button 
                  class="btn btn-info" 
                  @click="editСourse(row._id)"
                >
                  Редактировать
                </button>
              </span>
              <Badge 
                v-else-if="key.toString().toLowerCase() === 'status'" 
                pill 
                :type="getPillByStatus(cellData)"
              >
                {{ cellData }}
              </Badge>
              <span v-else>{{ cellData }}</span>
            </Cell>
          </template>
        </DataTable>
      </div>
      <div
        class="d-flex flex-wrap pt-3 pb-4 align-items-end mx-4 justify-content-end"
      >
        <div class="me-sm-4 mb-sm-0 mb-3">
          <p class="mb-2 small">
            Курсов на странице
          </p>
          <Select
            :data="[5, 10, 15, 25, 100]"
            :selected="dataTablePerPage"
            @change="(dataTablePerPage = $event), (dataTablePage = 1)"
          />
        </div>
        <div>
          <Pagination
            v-model="dataTablePage"
            :pages="Math.ceil(dataTable.length / dataTablePerPage)"
          >
            <template v-slot:next>
              Дальше
            </template>
            <template v-slot:previous>
              Предыдущая
            </template>
          </Pagination>
        </div>
      </div>
    </Card>
  </div>
</template>

<script>
import CourseService from "../../services/CourseService";

export default {
  name: "CourseList",
  data() {
    return {
      dataTableHeaders: ["Номер", "Название", "Первое сообщение", "Статус", "Действия"],
      dataTableSearch: "",
      dataTable: [],
      componentKey: 0,
      dataTablePage: 1,
      dataTablePerPage: 10,
    }
  },
  async mounted() {
    let courses = await CourseService.getAll();
    courses.forEach((course, index) => {
      this.$set(this.dataTable, index, {
        '_id': course._id,
        'name': course.name,
        'init_message': course.init_message,
        'status': CourseService.getStatusName(course.status),
        'edit': null
      });
    });
  },
  methods: {
    editСourse(id)
    {
      this.$router.push('/courses/' + id);
    },
    newCourse()
    {
      this.$router.push('/courses/new');
    },
    getPillByStatus(cellData) {
      switch (cellData) {
        case "Активный":
            return 'success';
        case "Архив":
            return 'warning';
      }
    }
  }
};
</script>

<style scoped>
</style>